import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Text } from "../atoms"

const AccoladeItem = ({ accolade_excerpt, accolade_text, rating, image }) => {
  const ratingStars = []
  for (let i = 0; i < rating; i++) {
    ratingStars.push(
      <ImageContainer key={i}>
        <Img
          style={{ marginRight: "8px" }}
          fixed={image.fixed}
          alt={rating + "rating"}
        />
      </ImageContainer>
    )
  }
  return (
    <Container>
      <Excerpt>"{accolade_excerpt.text}"</Excerpt>
      <Text>{accolade_text.text}</Text>
      <RatingContainer>{ratingStars}</RatingContainer>
    </Container>
  )
}

export default AccoladeItem

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-width: 18px;
    max-height: 18px;
  }
`
const Container = styled.div`
  @media (max-width: 800px) {
    justify-self: center;
  }
`

const Excerpt = styled.p`
  font-family: "Moderat Regular", sans-serif;
  color: var(--primary-text);
  padding: 0 0 24px 0;
  font-size: 1.5rem;
  @media (max-width: 800px) {
    font-size: 25px;
    text-align: center;
  }
`

const RatingContainer = styled.div`
  display: flex;
  margin: 32px 0;
  @media (max-width: 700px) {
    justify-content: center;
  }
`
