import React from "react"
import styled from "styled-components"
import { AwardTitle } from "./index"
import AccoladeItem from "./AccoladeItem"

const Accolades = ({ section_title, rating_icon, items }) => {
  return (
    <Container>
      <AwardTitle>{section_title.text}</AwardTitle>
      <ItemsContainer>
        {items.map((item, i) => (
          <AccoladeItem key={i} {...item} image={rating_icon} />
        ))}
      </ItemsContainer>
    </Container>
  )
}

export default Accolades

const Container = styled.div`
  display: grid;
  margin: 80px auto;
  @media (max-width: 800px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`

const ItemsContainer = styled.div`
  padding-top: 16px;
  display: grid;
  grid-gap: 40px 160px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 1400px) {
    grid-gap: 40px 120px;
  }
  @media (max-width: 1000px) {
    grid-gap: 40px 80px;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`
