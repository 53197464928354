import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useAwardsData } from "../hooks/useAwardsData"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageHeader from "../components/pageHeader"
import PLAwards from "../components/awards/"
import Accolades from "../components/awards/accolades"
import { withPreview } from "gatsby-source-prismic"

const sectionMapper = {
  header: PageHeader,
  awards: PLAwards,
  certifications: PLAwards,
  accolades: Accolades,
}

const Awards = ({ data: previewData }) => {
  const { uid, image, sections, bookData } = useAwardsData(previewData)
  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <Container>
        <ImageContainer>
          <Img fluid={image.fluid} alt={image.alt || "hero image"} />
        </ImageContainer>
        {sections.map((section, i) => {
          const Component = sectionMapper[section.slice_type]
          return (
            <Component key={i} {...section.primary} items={section.items} />
          )
        })}
      </Container>
    </Layout>
  )
}

export default withPreview(Awards)

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
`
const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    height: 400px;
    min-height: 250px;
  }
  padding: 40px 0;
  margin: 0 auto;
`
